import { useState } from "react"
import { requestResetPassword } from "../services/apiClient"
import Spinner from "./Spinner";
import { NavLink } from 'react-router-dom'
import Alert from "./Alert";

const RequestResetPasswordForm = () => {
    const [email, setEmail] = useState('')
    const [isLoading, setIsLoading] = useState(false)
    const [isRequested, setIsRequested] = useState(false)

    const [isError, setIsError] = useState(false)
    const [message, setMessage] = useState({})


    const handleRequestNewPassword = async (e) => {
        e.preventDefault()

        setIsLoading(true)

        setIsError(false)
        setMessage({})

        let passwordResetRequestResult = await requestResetPassword(email)

        if (passwordResetRequestResult.status === 200) {
            console.log('passwordResetRequestResult', passwordResetRequestResult)

            setIsLoading(false)
            setIsRequested(true)
        } else {
            console.log('passwordResetRequestResult', passwordResetRequestResult)
            setMessage({ title: 'Er is iets mis gegaan', message: passwordResetRequestResult.response.data.message })
            setIsError(true)
            setIsLoading(false)
        }
    }

    return (
        <div className="flex items-center justify-center">
            <div className="px-8 py-6 mt-4 text-left bg-white w-1/3">
                {!isRequested ?
                    <>
                        <h3 className="text-2xl font-bold text-center">Wachtwoord vergeten</h3>

                        <NavLink className='text-blue-600 text-sm hover:text-blue-800 my-4 block' to='/login'>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 inline align-middle">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5" />
                            </svg>
                            Terug
                        </NavLink>

                        <form onSubmit={e => handleRequestNewPassword(e)}>
                            <div className="mt-4">
                                <div>
                                    <label className="block" htmlFor="email">Email</label>
                                    <input
                                        value={email}
                                        onChange={e => setEmail(e.target.value)}
                                        type="email"
                                        placeholder="Email"
                                        className="w-full px-4 py-2 mt-2 border rounded-md focus:outline-none focus:ring-1 focus:ring-blue-600"
                                        required
                                    />
                                </div>

                                {isError &&
                                    <Alert title={message.title} message={message.message} color="red" />
                                }

                                <div className="flex items-baseline justify-between">
                                    <button
                                        type="submit"
                                        className="px-6 py-2 mt-4 text-white bg-blue-600 rounded-lg hover:bg-blue-900">
                                        {!isLoading ?
                                            <>Reset wachtwoord</>
                                            : <Spinner />}

                                    </button>
                                </div>
                            </div>
                        </form>
                    </>
                    : <div>
                        <h3 className="text-2xl font-bold text-center mb-10">Nieuw wachtwoord is aangevraagd</h3>
                        <p>Je hebt een verzoek gedaan om je wachtwoord te resetten. Als het e-mailadres dat je hebt ingevuld bij ons bekend is, ontvang je van ons een e-mail met een wachtwoordresetlink. Check je inbox (en spam-map, voor de zekerheid) en volg de instructies om je wachtwoord te resetten.</p>

                        <p className="my-8">Als je nog problemen hebt of vragen, neem dan gerust contact met ons op. We helpen je graag verder.</p>

                        <p>Klik <NavLink className='underline text-blue-600 hover:text-blue-800' to='/login'>hier</NavLink> om naar de loginpagina te gaan.</p>
                    </div>}
            </div>
        </div>
    )
}

export default RequestResetPasswordForm