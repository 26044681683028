import { useEffect, useState } from "react"
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { login, getCurrentUser } from "../services/apiClient";
import Alert from "./Alert";
import Spinner from "./Spinner";

const LoginForm = () => {
    const [password, setPassword] = useState('')
    const [email, setEmail] = useState('')

    const [isLoading, setIsLoading] = useState(false)
    const [isError, setIsError] = useState(false)

    const navigate = useNavigate()
    const location = useLocation()

    const handleLogin = async (e) => {
        setIsLoading(true)
        setIsError(false)
        e.preventDefault()

        login(email, password).then(res => {
            console.log(res)
            if (res.status === 200) {
                console.log('logging in')
                navigate('/ordering')
            } else {
                console.log('error', res)
                setIsLoading(false)
                setIsError(true)
            }
        })
    }

    useEffect(() => {
        (async () => {
            const result = await getCurrentUser()
            console.log('result.status', result.status)
            if (result.status === 200) {
                navigate('/ordering')
            }
        })()

    }, [navigate])

    return (
        <>
            <div className="flex items-center justify-center">
                <div className="px-8 py-6 mt-4 w-96 text-left bg-white ">
                    <h3 className="text-2xl font-bold text-center">Login</h3>
                    <form onSubmit={e => handleLogin(e)}>
                        <div className="mt-4">
                            <div>
                                <label className="block" htmlFor="email">Email</label>
                                <input
                                    value={email}
                                    onChange={e => setEmail(e.target.value)}
                                    type="email"
                                    placeholder="Email"
                                    className="w-full px-4 py-2 mt-2 border rounded-md focus:outline-none focus:ring-1 focus:ring-blue-600"
                                    required
                                />
                            </div>
                            <div className="mt-4">
                                <label className="block">Wachtwoord</label>
                                <input
                                    value={password}
                                    onChange={e => setPassword(e.target.value)}
                                    type="password"
                                    placeholder="Wachtwoord"
                                    className="w-full px-4 py-2 mt-2 border rounded-md focus:outline-none focus:ring-1 focus:ring-blue-600"
                                    required
                                />
                            </div>

                            {location.state?.passwordIsReset && !isError &&
                                <Alert title='Het wijzigen van je wachtwoord is gelukt' message='Log hieronder in met je nieuwe wachtwoord' color="green" />
                            }

                            {isError &&
                                <Alert title='Sorry, het inloggen is niet gelukt' message='Controleer je gebruikersnaam en wachtwoord en probeer het opnieuw' />
                            }

                            <div className="flex items-baseline justify-between">
                                <button type="submit" className="px-6 py-2 mt-4 text-white bg-blue-600 rounded-lg hover:bg-blue-900">
                                    {!isLoading ?
                                        <>Login</>
                                        : <Spinner />}

                                </button>
                                <NavLink to={'/login/resetpassword'} className="text-sm text-blue-600 hover:underline">
                                    Wachtwoord vergeten?
                                </NavLink>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}

export default LoginForm