import Header from '../components/Header';

const ColumnLayout = ({ children }) => {
    return (
        <>
            <Header />

            <main className="-mt-24 pb-8">
                <div className="mx-auto max-w-3xl px-4 sm:px-6 lg:max-w-7xl lg:px-8">

                    <div className="grid grid-cols-1 items-start gap-4 lg:grid-cols-3 lg:gap-8">
                        {/* Main view */}
                        <div className="grid grid-cols-1 gap-4 lg:col-span-2">
                            <section aria-labelledby="section-1-title">
                                <div className="overflow-hidden rounded-lg bg-white shadow">
                                    <div className="p-6">

                                        {/* <NameSelector /> */}
                                        {children[0]}


                                    </div>
                                </div>
                            </section>
                        </div>
                        {/* Sidebar */}
                        <div className="grid grid-cols-1 gap-4">
                            <section aria-labelledby="section-2-title">
                                <div className="overflow-hidden rounded-lg bg-white shadow">
                                    <div className="p-6">

                                        {children.slice(1)}

                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                </div>
            </main>
        </>
    )
}

export default ColumnLayout