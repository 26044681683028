import { useState } from "react"
import Spinner from "./Spinner";
import { useParams, useNavigate } from 'react-router-dom';
import { setResetPassword } from "../services/apiClient"
import { useEffect } from "react";
import Alert from "./Alert";

const NewPasswordForn = () => {
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [passwordConfirm, setPasswordConfirm] = useState('')

    const [isLoading, setIsLoading] = useState(false)
    const [isError, setIsError] = useState(false)

    const [errorMessage, setErrorMessage] = useState({})

    const params = useParams();
    const navigate = useNavigate()

    useEffect(() => {
        (async () => {
            console.log('params.token', params)
        })()

    }, [])

    const handleSubmitNewPassword = async (e) => {
        e.preventDefault()
        setIsLoading(true)
        setIsError(false)

        if (params.token) {
            if (password === passwordConfirm) {

                if (password.length < 8) {
                    setIsLoading(false)
                    setIsError(true)
                    setErrorMessage({title: 'Wachtwoord niet sterk genoeg', message: 'Je wachtwoord moet minimaal 8 karakters bevatten.'})
                } else {
                    const passwordResetObject = {
                        email: email,
                        password: password,
                        password_confirmation: passwordConfirm,
                        token: params.token
                    }
                    const result = await setResetPassword(passwordResetObject)
                    
                    if (result.status === 200) {
                        navigate('/login', { state: { passwordIsReset: true } })
                    } else {
                        setIsLoading(false)
                        setIsError(true)
                        console.log(result)
                        setErrorMessage({title: 'Wachtwoord wijzigen is niet gelukt', message: result.response.data.message})
                    }
                }
            } else {
                setIsLoading(false)
                setIsError(true)
                setErrorMessage({title: 'Wachtwoorden komen niet overeen', message: 'Wachtwoorden in de twee invoervelden moeten aan elkaar gelijkt zijn.'})
            }
        }
    }

    return (
        <div className="flex items-center justify-center">
            <div className="px-8 py-6 mt-4 text-left bg-white ">
                <h3 className="text-2xl font-bold text-center">Nieuw wachtwoord instellen</h3>
                <h2 className="italic pt-5 text-xl font-semibold">Stel je nieuwe wachtwoord in. Deze moet uit minimaal 8 karakters bestaan.</h2>
                <form onSubmit={e => handleSubmitNewPassword(e)}>
                    <div className="mt-4">
                        <div className="mt-4">
                            <label className="block">Bevestig email adres</label>
                            <input
                                value={email}
                                onChange={e => setEmail(e.target.value)}
                                type="email"
                                placeholder="Email adres"
                                className="w-full px-4 py-2 mt-2 border rounded-md focus:outline-none focus:ring-1 focus:ring-blue-600"
                            required
                            />
                        </div>
                        <div className="mt-4">
                            <label className="block">Wachtwoord</label>
                            <input
                                value={password}
                                onChange={e => setPassword(e.target.value)}
                                type="password"
                                placeholder="Wachtwoord"
                                className="w-full px-4 py-2 mt-2 border rounded-md focus:outline-none focus:ring-1 focus:ring-blue-600"
                            required
                            />
                        </div>
                        <div className="mt-4">
                            <label className="block">Herhaal wachtwoord</label>
                            <input
                                value={passwordConfirm}
                                onChange={e => setPasswordConfirm(e.target.value)}
                                type="password"
                                placeholder="Herhaal wachtwoord"
                                className="w-full px-4 py-2 mt-2 border rounded-md focus:outline-none focus:ring-1 focus:ring-blue-600"
                            required
                            />
                        </div>

                        {isError &&
                            <Alert title={errorMessage.title} message={errorMessage.message} />
                        }

                        <div className="flex items-baseline justify-between">
                            <button type="submit" className="px-6 py-2 mt-4 text-white bg-blue-600 rounded-lg hover:bg-blue-900">
                                {!isLoading ?
                                    <>Bevestig wachtwoord</>
                                    : <Spinner />}
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default NewPasswordForn