import axios from 'axios'

//development URL
// const BASE_URL = 'http://localhost'
// staging URL
// const BASE_URL = 'https://stamboek-api.markenwolter.nl'
// production URL
const BASE_URL = 'https://stamboek-api.scouting-alblasserdam.nl'

export const apiClient = axios.create({
    baseURL: BASE_URL,
    withCredentials: true,
});

apiClient.interceptors.response.use(response => {
    return response;
}, error => {

    return error;
});

export const getCurrentUser = async () => {
    return await apiClient.get('/api/users/me')
}

export const getCurrentBalanceCents = async () => {
    const currentUser = await getCurrentUser()
    return parseInt(currentUser.data.data.balance)
}

export const getUsers = () => {
    return apiClient.get('/api/users')
}

export const login = async (email, password) => {
    return apiClient.get('/sanctum/csrf-cookie')
        .then(res => {
            let loginResult = apiClient.post('/login', {
                email: email,
                password: password
            })
            console.log('loginResult', loginResult)
            return loginResult
        })
}

export const logout = () => {
    return apiClient.post('/logout')
}

export const increaseBalance = async (amount) => {
    return await apiClient.post('/api/users/increase', {
        amount: amount
    })
}

export const getPaymentStatus = async (paymentId) => {
    return await apiClient.get(`/api/increase-status/${paymentId}`)
}

export const createOrder = async (order) => {
    return apiClient.post('/api/orders', order)
}

export const requestResetPassword = async (email) => {
    return apiClient.get('/sanctum/csrf-cookie')
        .then(_ => {
            return apiClient.post('/forgot-password', {
                email: email
            })
        })
}

export const setResetPassword = async (passwordResetObject) => {
    return apiClient.post(`/reset-password`, passwordResetObject)
}

export const getOrderHistory = async () => {
    return apiClient.get('/api/orders/history')
}

export const getProducts = async () => {
    return apiClient.get('/api/products')
}