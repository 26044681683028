import { useState, useEffect } from 'react'
import { getCurrentBalanceCents, increaseBalance } from "../services/apiClient"
import { useParams } from 'react-router-dom';

import { formatEuros, totalCents } from "../utils/finance"
import Alert from './Alert'
import Spinner from './Spinner'


const BalanceIncreaser = () => {
    const [increaseBalanceWith, setIncreaseBalanceWith] = useState(0)
    const [currentBalanceCents, setCurrentBalanceCents] = useState(0)

    const [isLoaded, setIsLoaded] = useState(false)
    const [isIncreasing, setIsIncreasing] = useState(false)
    const [isError, setIsError] = useState(false)

    const params = useParams();


    useEffect(() => {
        (async () => {
            const result = await getCurrentBalanceCents()
            setCurrentBalanceCents(result)
            setIsLoaded(true)
        })()
    }, [])

    const handleIncrease = async () => {
        setIsIncreasing(true)
        setIsError(false)
        let res = await increaseBalance(increaseBalanceWith * 100)
        console.log('result from increase =>', res)

        if (res.status === 200) {

            window.location = res.data.url
        } else {
            setIsIncreasing(false)
            setIsError(true)
            console.log('no success', res)
        }
    }

    const validateInput = (value) => {
        if (value < 1)
        {
            setIncreaseBalanceWith(0)
        } else {
            setIncreaseBalanceWith(value)
        }
    }

    return (
        <>
            <h3 className="text-2xl font-bold">Ophogen</h3>
            <h1>Huidig saldo: <span className={isLoaded ? '' : ' animate-pulse'}>{isLoaded ? formatEuros(currentBalanceCents) : 'Laden'}</span></h1>
            <div className="flex">
                <button disabled={!isLoaded} onClick={() => validateInput(increaseBalanceWith - 1)} className="disabled:opacity-50 bg-transparent hover:bg-blue-500 text-blue-700 font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded">
                    -
                </button>

                <input value={increaseBalanceWith} onChange={e => validateInput(e.target.value)} type="number" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-24 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="1" />

                <button disabled={!isLoaded} onClick={() => validateInput(increaseBalanceWith + 1)} className="disabled:opacity-50 bg-transparent hover:bg-blue-500 text-blue-700 font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded">
                    +
                </button>

            </div>
            <h1>Saldo na ophogen: {formatEuros(currentBalanceCents + totalCents([increaseBalanceWith*100]))}</h1>

            {isError &&
                <Alert title='Helaas, het ophogen van je saldo is mislukt.' message='Neem contact met ons op als het probleem zich blijft voordoen. Dank je wel!' />
            }

            {params.status === 'success' &&
                <Alert title='Ophogen gelukt' message='' color='green' />
            }

            <button
                onClick={() => handleIncrease()}
                type="submit"
                disabled={!isLoaded || increaseBalanceWith === 0}
                className="disabled:opacity-50 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                {!isIncreasing ?
                    <>Ophogen</>
                    : <Spinner />}
            </button>
        </>
    )
}

export default BalanceIncreaser