import { useState } from 'react';
import { Outlet } from 'react-router-dom';
import Sidebar from '../components/Sidebar';
import ColumnLayout from "./ColumnLayout"

const AuthLayout = () => {

    
    const [trigger, setTrigger] = useState(true)

    return (
        <ColumnLayout>
            <Outlet context={[trigger, setTrigger]} />
            <Sidebar trigger={trigger} />
        </ColumnLayout>
    )
}

export default AuthLayout