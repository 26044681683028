import { useOutlet } from 'react-router-dom';
import Header from '../components/Header';


const LoginLayout = () => {
    const outlet = useOutlet()
    return (
        <div>
            {/* DELETE IN WHEN TO PROD */}
            <Header /> 

            {outlet}
        </div>
    )
}

export default LoginLayout