import React, { useEffect, useState } from 'react';
import { NavLink, useNavigate } from "react-router-dom";
import { getUsers, getCurrentUser } from '../services/apiClient';
import Spinner from './Spinner';

const NameSelector = () => {
    const [filter, setFilter] = useState('');
    const [users, setUsers] = useState([])
    const [user, setUser] = useState()

    const [isLoaded, setIsLoaded] = useState(false)
    const navigate = useNavigate()

    useEffect(() => {
        (async () => {
            const user = await getCurrentUser()
            if (user.data.data.bar_service === 0) {
                navigate('/ordering')
            }

            const result = await getUsers()
            setUsers(result.data.data)
            setIsLoaded(true)
        })()
    }, [])

    return (
        <>
            <h3 className="text-2xl font-bold">Bardienst</h3>
            <div className="flex justify-center">
                <input value={filter} onChange={event => setFilter(event.target.value)} name='filter' type="text" placeholder="Zoeken op naam" className="px-8 py-3 w-96 rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0 text-sm"></input>
            </div>

            <div className="flex justify-center">
                <ul className="bg-white rounded-lg w-full text-gray-900">
                    {isLoaded ?
                        users.filter(u => u.name.toLowerCase().includes(filter.toLowerCase()))
                            .map(user => (
                                <span key={user.id}>
                                        <NavLink to={`/barservice/ordering/${user.id}`} state={{ name: user.name }} >
                                            <li className="px-6 py-2 border-b border-gray-200 w-full" key={user.name}>{user.name}</li>
                                        </NavLink>
                                </span>
                            )) : <Spinner />}
                </ul>
            </div>
        </>
    )

}

export default NameSelector