import { useEffect, useState } from "react"
import { formatEuros } from "../utils/finance"
import { createOrder, getCurrentBalanceCents, getCurrentUser, getProducts } from "../services/apiClient"
import Spinner from "../components/Spinner";
import { useParams, useLocation, NavLink, useOutletContext } from 'react-router-dom';
import Alert from "./Alert";

const OrderSelector = () => {
    const [isLoaded, setIsLoaded] = useState(false)
    const [isOrdering, setIsOrdering] = useState(false)
    const [isError, setIsError] = useState(false)

    const [orderLines, setOrderLines] = useState([])
    const [products, setProducts] = useState([])

    const [grandTotal, setGrandTotal] = useState(0)
    const [currentBalanceCents, setCurrentBalanceCents] = useState(0)

    const [message, setMessage] = useState({})

    const params = useParams();

    const location = useLocation();
    const { state } = location;

    const [trigger, setTrigger] = useOutletContext();

    const [user, setUser] = useState()


    const createStateLine = async (availableProducts) => {
        let orderLinesObject = []

        availableProducts.forEach(availableProduct => {
            let newOrderLine = {
                id: availableProduct.id,
                quantity: 0
            }
            orderLinesObject.push(newOrderLine)
        });
        return orderLinesObject
    }

    const calculateGrandTotal = () => {
        let total = 0

        if (orderLines.length > 0) {

            products.map(product => {
                let productPrice = product.price
                let quantity = orderLines.find(o => o.id === product.id).quantity

                return total += productPrice * quantity
            })
        }

        setGrandTotal(total)
    }

    const increaseProduct = (productId) => {
        let orderLinesObject = [...orderLines]

        let newOrderLinesObject = orderLinesObject.map(line => {
            let newLine
            if (line.id === productId) {
                newLine = { ...line, quantity: line.quantity + 1 }
                return newLine
            }
            return line
        })

        setOrderLines(newOrderLinesObject)
    }

    const decreaseProduct = (productId) => {
        let orderLinesObject = [...orderLines]

        let newOrderLinesObject = orderLinesObject.map(line => {
            let newLine
            if (line.id === productId) {
                let newQuantity = line.quantity > 1 ? line.quantity - 1 : 0
                newLine = { ...line, quantity: newQuantity }
                return newLine
            }
            return line
        })

        setOrderLines(newOrderLinesObject)
    }

    const handleCreateOrder = async () => {
        setIsOrdering(true)
        setIsError(false)
        setMessage({})

        let order_for_user

        if (params.id) {
            order_for_user = params.id
        } else {
            order_for_user = await (await getCurrentUser()).data.data.id
        }

        let order = {
            order_for_user: order_for_user,
            products: orderLines.filter(line => line.quantity > 0)
        }

        console.log('order', order)


        let res = await createOrder(order)

        console.log('result from create order =>', res)
        if (res.status) {
            setOrderLines([])
            setIsOrdering(false)
            setMessage({title: 'Gelukt!', message: 'Je bestelling is afgeschreven.'})

            setTrigger(!trigger)
            console.log('order was a success', res)
        } else {
            setIsOrdering(false)
            setIsError(true)
            console.log('no success', res)
        }
    }

    useEffect(() => {
        (async () => {
            if (orderLines.length < 1) {
                const result = await getCurrentBalanceCents()
                setCurrentBalanceCents(result)

                const availableProducts = await (await getProducts()).data.data
                setProducts(availableProducts)

                let res = await createStateLine(availableProducts)
                setOrderLines(res)

                const user = await getCurrentUser()
                if (user.status === 200) {
                    setUser(user)
                }


                setIsLoaded(true)
            }
            calculateGrandTotal()
        })()

        // eslint-disable-next-line
    }, [orderLines])

    return (
        <>
            <h1 className="text-2xl font-bold">Afschrijven {params.id ? `voor ${state.name}` : ''}</h1>

            {params.id &&
                <NavLink className='text-blue-600 text-sm hover:text-blue-800 my-4 block' to='/barservice'>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 inline align-middle">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5" />
                    </svg>
                    Terug
                </NavLink>
            }

            {!params.id &&
                <h2 className="text-xl mt-6 mb-4">Huidig saldo: <span className={currentBalanceCents < 0 ? 'text-red-700' : ''}>{formatEuros(currentBalanceCents)}</span></h2>
            }

            {isLoaded && orderLines.length === products.length ?

                products
                .filter(p => !(p.name.toLowerCase().includes('bier') && user.data.data.bar_service === 0))
                .map((product, i) => (
                    <div key={i} className="flex items-center my-2">

                        <p className="flex-initial w-1/3">{product.name}</p>
                        <p className="mx-6">{formatEuros(product.price)}</p>

                        <button onClick={() => decreaseProduct(product.id)} className="bg-transparent hover:bg-blue-500 text-blue-700 font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded">
                            -
                        </button>

                        {/* TODO: you cannot directly edit the field. Requires array modification. Possible but not worth it for basic functionality  */}
                        {/* onChange={e => setAmountSoda(e.target.value)} */}
                        <input value={orderLines[i].quantity} type="number" disabled className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-24 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="0" />

                        <button onClick={() => increaseProduct(product.id)} className="bg-transparent hover:bg-blue-500 text-blue-700 font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded">
                            +
                        </button>

                        <p className="ml-4">{formatEuros(orderLines[i].quantity * product.price)}</p>
                    </div>

                ))
                : <Spinner />
            }
            <h2 className="text-xl mt-6 mb-4">Totaal van de bestelling: {formatEuros(grandTotal)}</h2>
            {!params.id &&
                <h2 className="text-xl mt-6 mb-4">Saldo na afschrijven: <span className={currentBalanceCents - grandTotal < 0 ? 'text-red-700' : ''}>{formatEuros(currentBalanceCents - grandTotal)}</span></h2>
            }

            {isError &&
                <Alert title='Bestelling mislukt. Probeer opnieuw.' message='Neem contact met ons op als het probleem zich blijft voordoen. Dank je wel!' />
            }


            {Object.entries(message).length !== 0 &&
                <Alert title={message.title} message={message.message} color="green" />
            }

            <button
                onClick={() => handleCreateOrder()}
                type="submit" disabled={grandTotal === 0 ? true : false}
                className='disabled:opacity-50 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800'>
                {!isOrdering ?
                    <>Afschrijven</>
                    : <Spinner />}
            </button>
        </>
    )
}

export default OrderSelector