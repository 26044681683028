import { useEffect } from "react"
import { logout } from "../services/apiClient"
import { useNavigate } from "react-router-dom";
import Spinner from "../components/Spinner";

const LogoutProcessor = () => {
    const navigate = useNavigate()


    useEffect(() => {
        (async () => {
            await logout()

            navigate('/')

        })()

    }, [])

    return (
        <>
            Logging you out
            <Spinner />
        </>
    )
}

export default LogoutProcessor