import { useParams, NavLink } from 'react-router-dom';
import { useState, useEffect } from 'react'
import { getCurrentBalanceCents, getPaymentStatus } from "../services/apiClient"
import Alert from './Alert'
import Spinner from './Spinner';
import { formatEuros, mapPaymentStatusToMessage } from "../utils/finance"

const REFRESH_INTERVAL = 5000; // Refresh interval in milliseconds (5 seconds)

const PaymentStatusChecker  = () => {

    const [isLoaded, setIsLoaded] = useState(false)
    const [status, setStatus] = useState('')
    const [currentBalanceCents, setCurrentBalanceCents] = useState(0)

    const params = useParams();

    const fetchPaymentStatus = async () => {
        try {
            console.log('params', params);
    
            const result = await getPaymentStatus(params.increaseId);
            console.log(result);
            setStatus(result.data.status);
    
            const balance = await getCurrentBalanceCents();
            setCurrentBalanceCents(balance);
    
            setIsLoaded(true);
          } catch (error) {
            console.error("Error fetching payment status and balance:", error);
          }
    }

    useEffect(() => {
        (async () => {

            fetchPaymentStatus()

            const interval = setInterval(() => {
                fetchPaymentStatus()
            }, REFRESH_INTERVAL)

            return () => clearInterval(interval)

        })()
    }, [params.increaseId])

    return (
        <>
            <h3 className="text-2xl font-bold">Ophoog status</h3>
            {isLoaded ?
                <>
                    <NavLink className='text-blue-600 text-sm hover:text-blue-800 my-4 block' to='/ordering'>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 inline align-middle">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5" />
                        </svg>
                        Naar afschrijven
                    </NavLink>

                    <Alert title={mapPaymentStatusToMessage(status)} message='' color='gray' />

                    <h2 className="text-xl mt-6 mb-4">Huidig saldo: <span className={currentBalanceCents < 0 ? 'text-red-700' : ''}>{formatEuros(currentBalanceCents)}</span></h2>

                </>
                :
                <Spinner />
            }
        </>
    )
}

export default PaymentStatusChecker 