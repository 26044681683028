export const formatEuros = (cents) => {
    var euros = (cents/100).toFixed(2).replace(".",",")
    return `€${euros}`
} 

export const totalCents = (orderlinesCents) => {
    const orderTotal = orderlinesCents.reduce((acc, curr) => {{
        return acc + curr
    }})
    return orderTotal
}

export function mapPaymentStatusToMessage(status) {
    const statusMessages = {
      paid: "Ophogen gelukt",
      failed: "Ophogen mislukt",
      canceled: "Ophogen geannuleerd",
      pending: "Ophogen in behandeling",
      authorized: "Ophogen geautoriseerd",
      expired: "Ophogen verlopen",
      open: "Ophogen openstaand",
    };
  
    return statusMessages[status] || "Onbekende status";
  }